import {IUser, IUserWithRelations} from '@user/interfaces';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {UtilityListComponent} from '@shared/components/ui-elements/utility-list/utility-list.component';
import {UtilityClass} from '@shared/utils/utility.class';
import {IUserState, UserActions} from '@user/state';

export interface IUserAdminState {
  list: IUserWithRelations[];
  selected: string | null;
  count: number;
}

export namespace AdminUserActions {
  export class Get {
    static readonly type: string = '[AdminUserState] Get';

    constructor(
      public filters?: PrismaFilter<IUserWithRelations>,
      public addTo: boolean = false,
      public params?: { includeMyself: boolean }
    ) { }
  }

  export class GetById {
    static readonly type: string = '[AdminUserState] Get By Id';

    constructor(public id: string, public addTo: boolean = false) {
    }
  }

  export class Add {
    static readonly type: string = '[AdminUserState] Add';

    constructor(public admin: IUserWithRelations) {
    }
  }

  export class AddMany {
    static readonly type: string = '[AdminUserState] Add Many';

    constructor(public admins: IUserWithRelations[]) {
    }
  }

  export class Count {
    static readonly type: string = '[AdminUserState] Count';
    constructor(
      public filters?: PrismaFilter<IUserWithRelations>,
      public params?: { includeMyself: boolean }
    ) { }
  }

  export class DeleteById {
    static readonly type: string = '[AdminUserState] Delete by id';
    constructor(public id: string) {
    }
  }

  export class Reset {
    static readonly type: string = '[AdminUserState] Reset';
    constructor() {
    }
  }

}

const _DEFAULT_DATA: IUserAdminState = {
  list: [],
  selected: null,
  count: 0,
}

@State<IUserAdminState>({
  name: 'AdminUserState',
  defaults: _DEFAULT_DATA,
})
@Injectable()
export class AdminUserState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  @Selector()
  static getUserAdmins({list}: IUserAdminState): IUserWithRelations[]  {
    return list;
  }

  @Selector()
  static getCount({count}: IUserAdminState): number {
    return count;
  }

  @Action(AdminUserActions.Get)
  async get({patchState, getState}: StateContext<IUserAdminState>, {filters, addTo, params}: AdminUserActions.Get): Promise<void> {
    const response: DataBaseServiceResponse<IUserWithRelations[]> = await firstValueFrom(this.baseService.get<IUserWithRelations[]>(`${this.SERVER}/users/admins`, filters, { params }));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    const list = addTo ? [...getState().list].concat(response.entity ?? []) : response.entity ?? [];
    patchState({list: list as IUserWithRelations[]});
  }

  @Action(AdminUserActions.GetById)
  async getById({patchState, getState}: StateContext<IUserAdminState>, {id, addTo}: AdminUserActions.GetById): Promise<void> {
    const response: DataBaseServiceResponse<IUserWithRelations> = await firstValueFrom(this.baseService.get<IUserWithRelations>(`${this.SERVER}/users/admins/${id}`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list = addTo ? [...getState().list].concat(response.entity ?? []) : response.entity ?? [];
    patchState({list: list as IUserWithRelations[]});
  }

  @Action(AdminUserActions.Add)
  async add({patchState, getState}: StateContext<IUserAdminState>, {admin}: AdminUserActions.Add): Promise<void> {
    let list: IUserWithRelations[] = UtilityClass.updateOrPushItems(getState().list, admin, 'id');

    patchState({list: list});
  }

  @Action(AdminUserActions.AddMany)
  async addMany({patchState, getState}: StateContext<IUserAdminState>, {admins}: AdminUserActions.AddMany): Promise<void> {
    let list: IUserWithRelations[] = getState().list ?? [];
    for (const admin of admins) {
      list = UtilityClass.updateOrPushItems(list, admin, 'id');
    }

    patchState({ list });
  }

  @Action(AdminUserActions.Count)
  async count({patchState}: StateContext<IUserAdminState>, {filters, params}: AdminUserActions.Count): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IUserWithRelations>(`${this.SERVER}/users/admins/count`, filters, { params }));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity!});
  }

  @Action(AdminUserActions.DeleteById)
  async deleteById({patchState, getState}: StateContext<IUserAdminState>, {id}: AdminUserActions.DeleteById): Promise<void> {
    const response: DataBaseServiceResponse<IUserWithRelations> = await firstValueFrom(this.baseService.delete<IUserWithRelations>(`${this.SERVER}/users/admins/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.deleteItemByProp<IUserWithRelations>(getState().list, 'id', id),
      selected: null,
    });
  }

  @Action(AdminUserActions.Reset)
  reset({setState}: StateContext<IUserAdminState>): void {
    setState(_DEFAULT_DATA);
  }
}
